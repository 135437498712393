export default {
  app: {
    title: 'AxialAdmin Magyarország'
  },

  pages: {
    index: 'Kezdőlap'
  },

  errorPage: {
    title: '404',
    description: 'A keresett oldal nem található',
    homeBtn: 'Vissza a főoldalra'
  },

  menu: {
    tenders: 'Pályázatok',
    parcels: 'Táblák',
    customers: 'Partnerek',
    calculator: 'Kalkulátor',
    offers: 'Ajánlatok',
    orders: 'Rendelések',
    products: 'Szolgáltatások',
    availableOrders: 'Függő rendelések',
    logout: 'Kijelentkezés',
    personalData: 'Személyes adatok'
  },

  customer: {
    activate: 'Ügyfél aktiválása',
    offers: 'Ajánlatok megnyitása',
    offer: {
      list: {
        title: 'Ügyfél árajánlatainak/projektjeinek listája'
      }
    },
    details: {
      title: 'Partner adatai',
      label: {
        name: 'Név',
        email: 'Email',
        status: 'Státusz',
        legalRepresentative: 'Területi képviselő',
        phoneNumber: 'Telefonszám'
      }
    },
    list: {
      title: 'Partnerek',
      header: {
        name: 'Név',
        email: 'Email cím',
        status: 'Státusz',
        delegate: 'Területi képviselő'
      }
    },
    status: {
      true: 'Aktív',
      false: 'Inaktív'
    },
    filter: {
      status: 'Státusz szűrő'
    },
    dialog: {
      activate: {
        title: 'Ügyfél felhasználójának aktiválása',
        text: 'Biztosan aktiválja a(z) <strong>{name}</strong> nevű ügyfél felhasználóját?'
      },
      onBack: {
        title: 'Mentetlen adatok',
        text: 'Az űrlap mentetlen adatokat tartalmaz! Biztosan visszalép? Ezzel a mentetlen adatok elvesznek.'
      }
    },
    notification: {
      activateSuccess: 'Partner felhasználója sikeresen aktiválva!',
      activateError: 'Partner felhasználójának aktiválása során hiba lépett fel!',
      appUrlMissing: 'Alkalmazás URL nincs bekonfigurálva'
    }
  },

  activityLog: {
    actionType: {
      productGroupOrdered: 'Csomag megrendelve',
      offerTransportContractSigned: 'Szállítási megállapodás aláírva',
      offerTransportContractRequested: 'Szállítási megállapodás igényelve',
      parcelCreated: 'Tábla létrehozva ({metadata} db)',
      parcelFinalized: 'Tábla véglegesítve ({metadata} db)',
      offerFinalized: 'Ajánlat véglegesítve',
      orderItemCompleted: 'Megrendelés teljesítve',
      productGroupCompleted: 'Szolgáltatás teljesítve'
    }
  },

  offer: {
    activityLog: 'Eseménynapló',
    farmName: 'Gazdaság neve',
    partnerAddress: 'Ügyfél címe',
    filter: {
      status: 'Státusz',
      year: 'Év'
    },
    status: {
      offerCreated: 'Ajánlat létrehozva',
      offerAccepted: 'Kitöltés alatt',
      userFilled: 'Véglegesítésre vár',
      finalized: 'Véglegesítve',
      transportContractRequested: 'Szállítási megállapodás igényelve',
      transportContractSigned: 'Szállítási megállapodás aláírva',
      archived: 'Archiválva'
    },
    admin: {
      list: {
        title: 'Árajánlatok/Projektek listája',
        header: {
          offerCode: 'Ajánlat száma',
          companyName: 'Cégnév',
          farmName: 'Gazdaság neve',
          contactName: 'Kapcsolattartó neve',
          partnerEmail: 'Kapcsolattartó email címe',
          partnerContact: 'Elérhetőség',
          contractStatus: 'Szállítási megállapodás',
          parcelNumber: 'Gazdasághoz rendelt táblák száma',
          status: 'Státusz',
          offerAccess: 'Modul hozzáférések'
        }
      }
    },
    dialog: {
      acceptOffer: {
        title: 'Elfogadás megerősítése',
        text: 'Biztos elfogadja az ajánlatot?'
      },
      archiveOffer: {
        title: 'Archiválás megerősítése',
        text: 'Biztos arhiválja az ajánlatot?'
      },
      approveContract: {
        title: 'Jóváhagyás megerősítése',
        text: 'Biztos minden adat stimmel?'
      }
    },
    notification: {
      archiveSuccess: 'Árajánlat sikeresen archiválva!',
      archiveError: 'Árajánlat archiválása során hiba lépett fel!',
      acceptSuccess: 'Árajánlat sikeresen elfogadva!',
      acceptError: 'Árajánlat elfogadása során hiba lépett fel!'
    },
    label: {
      finalizedParcelNumber: 'Véglegesített táblák száma: ',
      lastFinalizedParcelShapesNumber: 'Generált táblák száma: '
    },
    btn: {
      downloadParcelsShapes: 'Táblahatárok letöltése',
      generateParcelsShapes: 'Táblahatárok Shape fájl generálása'
    }
  },

  parcel: {
    filter: {
      state: 'Státusz'
    },
    status: {
      finalized: 'Véglegesítve',
      editing: 'Szerkesztés alatt',
      NEED_YEARLY_DATA: 'Javítás szükséges',
      waiting_for_finalization: 'Véglegesítésre vár'
    },
    parcelEdit: 'Tábla szerkesztése',
    list: {
      title: 'Táblák',
      header: {
        area: 'Terület (vektoros)',
        name: 'Tábla neve',
        source: 'Táblahatár forrása',
        status: 'Státusz',
        state: 'Kitöltve',
        proplantaStatus: 'Proplanta státusz'
      }
    },
    source: {
      vantageDraw: 'Rajzolt',
      vantageFileUpload: 'Fájlból',
      agroMap: 'AgroMAP'
    }
  },

  offerSummary: {
    header: {
      name: 'Csomag',
      type: 'Típus',
      netPrice: 'Nettó ár',
      tax: 'ÁFA ({tax}%)',
      grossPrice: 'Bruttó ár',
      amount: 'Mennyiség'
    },
    footer: {
      total: 'Végösszeg',
      totalNotice: '(Fenntartási költségek nélkül)'
    }
  },

  offerCalculator: {
    stepper: {
      calculator: 'Kalkulátor',
      summary: 'Összesítő',
      partnerData: 'Partner adatok'
    }
  },

  requestOffer: {
    companyName: 'Cégnév',
    address: 'Cím',
    taxNumber: 'Adószám',
    contactPersonName: 'Kapcsolattartó neve',
    contactPersonEmail: 'Kapcsolattartó email címe',
    contactPersonPhoneNumber: 'Kapcsolattartó telefonszáma',
    accept:
      'Beleegyezem, hogy a fenti adataimat az AXIÁL Kft. a Precíziós szolgáltatásokhoz kapcsolódó ajánlat elküldése érdekében tárolja, valamint a megadott elérhetőségeken felkeressen.',
    tendedersData: 'Ajánlatkérő adatai:',
    recordRequest: 'Árajánlat rögzítése',
    companyRegistrationNumber: 'Cégjegyzékszám',
    hint: {
      companyRegistrationNumber: 'BS-CF-NNNNNN formátumban.',
      taxNumber: 'xxxxxxxx-y-zz formátumban.'
    }
  },

  product: {
    label: {
      productName: 'Szolgáltatás neve',
      productCode: 'Szolgáltatás kódja',
      productGroupCode: 'Csomag kódja',
      farmName: 'Gazdaság neve',
      orderedParcelCount: 'Rendelt táblák száma',
      orderedArea: 'Rendelt terület mérete',
      orderedDate: 'Rendelés dátuma',
      status: 'Státusz',
      completion: 'Teljesítés',
      parcel: 'Tábla',
      soilSample: 'Talajminta',
      soilSampleDownload: 'Talajminta letöltés',
      soilAnalysisDownload: 'Elkészült talajlabor letöltés'
    },
    status: {
      inProgress: 'Folyamatban',
      completed: 'Teljesítve',
      dataArrived: 'Adat betöltés alatt'
    },
    unitType: {
      area: 'ha',
      hectare: 'ha',
      piece: 'db',
      null: ''
    },
    admin: {
      list: {
        title: 'Szolgáltatások'
      }
    },
    areaLimit: {
      empty: 'Nincs megadva'
    },
    dialog: {
      parcelList: {
        title: 'Rendelt táblák',
        label: {
          name: 'Név',
          status: 'Státusz'
        },
        status: {
          inProgress: 'Folyamatban',
          completed: 'Teljesítve'
        }
      },
      excelUpload: {
        title: 'Excel feltöltése: {farmName} - {name}'
      },
      complete: {
        title: 'Szolgáltatás teljesítettre állítása',
        text: 'Biztosan teljesítettre akarja állítani a szolgáltatás státuszát?'
      }
    },
    notification: {
      completeSuccess: 'Sikeresen teljesítettre állította a szolgáltatás státuszát!',
      completeError: 'A szolgáltatás teljesítettre állítása során hiba lépett fel!'
    },
    messages: {
      excelDownloadError: 'Hiba történt a fájl letöltése során',
      excelUploadError: 'Hiba történt a fájl feltöltése során',
      excelUploadSuccess: 'Sikeres feltöltés',
      shapeFilesDownloadError: 'Hiba történt a fájlok letöltése során'
    }
  },

  calculator: {
    areaLimit: 'Maximum bevonható terület',
    addedProductGroups: 'Hozzáadott csomagok',
    productGroup: {
      selector: 'Csomag választás',
      yearlyPrice: 'Éves fenntartási költség',
      select: 'Csomag kiválasztása',
      remove: 'Nem kérem a csomagot',
      price: 'Csomag ára',
      dependencies: 'Előfeltétel',
      exludes: 'Kizáró feltétel'
    },
    validation: {
      hasExcludes: 'Már hozzáadott olyan csomagokat, melyek kizáró feltételei a hozzáadni kívánt csomagnak!',
      hasNoDependency: 'A hozzáadni kívánt csomagnak nem teljesülnek az előfeltételei!',
      hasDependencyOnRemove: 'A törölni kivánt csomag másik választott csomag előfeltétele, így előbb azokat kell törölni!',
      missingAreaLimit: 'A maximum bevonható terület nincs kitöltve!',
      amountIsBiggerThenAreaLimit: 'A megadott mennyiség nagyobb mint a maximum bevonható terület!',
      missingSelectedProductGroups: 'Minimum egy csomagot ki kell választani az ajánlat létrehozásához!'
    },
    dialog: {
      createOffer: {
        title: 'Megerősítés',
        text: 'Biztosan létrehozza az ajánlatot?'
      },
      onBack: {
        title: 'Mentetlen adatok',
        text: 'Az űrlap mentetlen adatokat tartalmaz! Biztosan visszalép?'
      },
      dataToLoad: {
        title: 'Mentetlen adatok',
        text: 'Szeretné betölteni a korábban nem mentett adatait?'
      }
    },
    notification: {
      offerCreatedSuccess: 'Ajánlat sikeresen létrehozva!',
      offerCreatedError: 'Ajánlat létrehozása közben hiba történt!'
    },
    btn: {
      edit: 'Szerkesztés',
      undo: 'Visszavonás'
    }
  },

  order: {
    filter: {
      billed: {
        yes: 'Igen',
        no: 'Nem',
        label: 'Számlázva'
      }
    },
    label: {
      productGroupName: 'Csomag neve',
      offerCode: 'Ajánlat száma',
      farmName: 'Gazdaság neve',
      partnerName: 'Kapcsolattartó neve',
      orderDate: 'Rendelés dátuma',
      status: 'Státusz',
      billed: 'Számlázva'
    },
    status: {
      created: 'Megrendelve',
      inProgress: 'Folyamatban',
      completed: 'Teljesítve',
      orderable: 'Rendelhető',
      notOrderable: 'Nem rendelhető'
    },
    admin: {
      list: {
        title: 'Rendelések'
      }
    }
  },

  label: {
    or: 'vagy',
    and: 'és',
    browse: 'Tallózás'
  },

  button: {
    edit: 'Szerkesztés',
    open: 'Megtekintés',
    delete: 'Törlés',
    move: 'Áthelyezés',
    downloadOffer: 'Ajánlat letöltése',
    requestDeliveryContract: 'Szállítási megállapodás igénylése',
    requestNewOffer: 'Új árajánlat igénylése',
    save: 'Mentés',
    finalize: 'Véglegesítés',
    back: 'Vissza',
    addNewTable: 'Új tábla felvétele',
    order: 'Megrendelés',
    accept: 'Elfogadás',
    archive: 'Archiválás',
    approve: 'Jóváhagyás',
    openContract: 'Szerződés megnyitása',
    addNewPriceOffer: 'Új árajánlat létrehozása',
    openParcels: 'Táblák megtekintése',
    completeProduct: 'Szolgáltatás teljesítése',
    completeParcel: 'Tábla teljesítése',
    downloadExcel: 'Fájlok letöltése',
    uploadExcel: 'Excel feltöltése',
    upload: 'Feltöltés',
    downloadShapeFiles: 'Shape fájlok letöltése',
    goToUser: 'Ugrás a felhasználóra'
  },
  filter: {
    search: 'Keresés'
  },
  maximap: {
    modul: {
      map: 'Térkép',
      plan: 'Kijuttatási terv',
      monitoring: 'Monitoring'
    }
  }
};
